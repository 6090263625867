.employee-bio {
    padding-top: 60px;
    position: relative;
    list-style: none;
    padding-bottom: 90px;
    margin-left: 50px;
    margin-right: 50px;
    line-height: 2;
    text-align: center;
}

.eb-columns {
    display: table;
    width: 100%;
}

#eb-image-wrapper {
    position: relative;
    width: 250px;
    height: 250px;
    top: 0;

}

.eb-image {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 50%;
    top: 0;
    border: 6px solid #6AE1C6;
    filter: drop-shadow(7px 7px 15px rgba(0, 0, 0, 0.175));
    left: 50%;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
}

.eb-bio {
    padding-left: 75px;
}

.eb-header {
    font-family: 'Eveleth';
    letter-spacing: 3px;
}

.eb-name {
    font-size: 26px;
    color: #6AE1C6;
    line-height: 1.5 !important;
}

.eb-title {
    font-family: "Graphie";
    letter-spacing: 0px;
    color: #4D4E56;
    font-weight: 300;
    font-size: 21px;
    padding-top: 2px;
}

.eb-body {
    font-family: "Graphie";
    padding-top: 40px;
    font-size: 16px;
    color: #4D4E56;
    white-space: pre-line;
}

.eb-column {
    display: table-cell;
    position: relative;
}

@media screen and (max-width: 768px) {
    .eb-column {
        width: 100%;
        float: left;
        display: block;
    }

    #eb-image-wrapper {
        width: 100%;
        margin-bottom: 65px;
    }

    .eb-image {
        height: 100%;
        width: auto;
    }

    .eb-bio {
        padding-left: 0;
    }

    .eb-body {
        font-size: 16px;
    }

    .employee-bio {
        margin-right: 35px;
        margin-left: 35px;
    }
}
@media (max-width: 320px) {
    .employee-bio {
        margin-right: 7px;
        margin-left: 7px;
    }
}
