#contact-us-form {
  background-color: white;
  /*position: absolute;
  bottom: 0;*/
  width: 100%;
  padding: 0 25px 60px;
  position: relative;
}

#contact-us-container {
  width: auto;
  height: auto;
  max-width: 750px;
  margin: auto;
  padding-top: 60px;
}

div#contact-us-header {
  margin-bottom: 25px;
  text-align: center;
}

span#contact-us-header {
  color: #6AE1C6;
  font-family: "Graphie";
  text-transform: uppercase;
  font-size: 54px;
  font-weight: 200;
}

@media (max-width: 768px) {
  span#contact-us-header {
    font-size: 32px;
  }
}

input,
textarea {
  border-radius: 0;
  -webkit-appearance: none;
}

input::placeholder,
textarea::placeholder {
  color: #777 !important;
  transition: 0.2s ease-in-out;
}

input:focus::placeholder,
textarea:focus::placeholder {
  color: #ddd !important;
}

textarea {
  resize: none;
  height: 150px !important;
}

div.contact-us {
  padding-bottom: 20px;
}

input.contact-us,
textarea.contact-us {
  width: 100% !important;
  border: 0;
  border-bottom: 1px solid lightgray;
  font-family: "Graphie";
  font-weight: 400;
  font-size: 16px;
  padding: 5px;
  outline: none;
  transition: border-bottom 0.1s;
}

input.contact-us-err,
textarea.contact-us-err {
  border-bottom: 2px solid #f7aeae;
}

span.contact-us-err-hide {
  color: white !important;
}
span.contact-us-err {
  font-family: "Graphie";
  color: #ff8686;
  font-size: 10px;
  padding-left: 5px;
  transition: color 0.1s ease-in-out;
}

.submit-contact-us {
  background-color: #6AE1C6;
  color: white;
  font-family: "Graphie";
  font-weight: 300;
  font-size: 18px;
  border: 0;
  padding: 9px;
  outline: none;
  border-radius: 6px;
  width: 175px;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 15px;
}

.sending {
  background-color: #827c80;
  pointer-events: none;
}

button:disabled {
  opacity: 0.7;
}

div#contact-us-alert {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 3px;
}
span#contact-us-alert {
  color: rgb(51,51,51);
  font-family: "Graphie";
}

.contact-us-alert-err {
  background-color: #f7aeae;
}
.contact-us-alert-success {
  background-color: #bde6d7;
}
