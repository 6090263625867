.splash-screen {
    background-color: #6AE1C6;
    position:fixed;
    top: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    /*z-index: 10000;*/
}

.loading-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 20vh;
}
