p.dropCap:first-letter {
    position: relative;
    float: left;
    display: block;
    font-size: 141px;
    line-height: 105px;
    font-weight: 100;
    padding-top: 8px;
    padding-right: 15px;
    padding-left: 0;
    color: #6AE1C6;
    font-family: "Graphie";
    text-transform: uppercase;
}
.articlePage p {
    color: #4D4E56;
    line-height: 1.6 !important;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 15px !important;
    font-family: "Graphie";
}
.articlePage p strong {
    color: #4D4E56;
}
h1, h2, h3, h4, h5, h6 {
    line-height: inherit !important;
    font-family: "Graphie";
    margin-top: 30px !important;
    margin-bottom: 20px !important;
}

h1 {
    font-weight: 200 !important;
    font-size: 54px !important;
    text-transform: uppercase;
    color: #6AE1C6;
    margin-bottom: 20px !important;
    line-height: 1.25 !important;
}
mark {
    color: inherit;
}
h2 {
    font-weight: 200 !important;
    font-size: 52px !important;
    color: #007589;
    line-height: 1.25 !important;
    text-transform: uppercase;
    margin-bottom: 20px !important;
}
h3 {
    font-weight: 300 !important;
    font-size: 36px !important;
    line-height: 1.25 !important;
    color: #007589;
}
h4 {
    font-weight: 400 !important;
    font-size: 22px !important;
    color: #007589;
    line-height: 1.5 !important;
}
h5 {
    font-weight: 400 !important;
    font-size: 18px !important;
    color: #898A8D;
    text-transform: uppercase;
    letter-spacing: 2px !important;
}
h6 {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #898A8D;
    text-transform: uppercase;
    letter-spacing: 2px !important;
}
blockquote {
    color: #7FBAC4;
    padding-left: 30px;
    font-size: 15px !important;
    font-weight: 500;
    margin-top: 40px !important;
    margin-bottom: 40px !important;
    font-family: "Graphie";
    border-left-style: solid;
    border-left-width: 5px;
    border-left-color: #7FBAC4;
}
.articleAnchor, .articleAnchor:link {
    color: #7FBAC4 !important;
    text-decoration: underline;
    font-size: 16px;
}
.articleUl {
    padding-inline-start: 35px;
    margin-top: 20px !important;
}
.articleUl li {
    color: #4D4E56;
    font-size: 16px !important;
    list-style: none;
    position: relative;
    line-height: 1.6 !important;
    font-family: "Graphie";
    margin-bottom: 15px !important;
}
.articleUl li::before {
    color: #6AE1C6;
    content: "\2022";
    display: inline-block;
    font-size: 35px;
    left: -35px;
    position: absolute;
    top: -18px !important;
}
.articleOl {
    counter-reset: li;
    padding-inline-start: 65px;
    margin-top: 20px !important;

}
.articleOl li {
    color: #4D4E56;
    font-size: 16px !important;
    font-family: "Graphie";
    line-height: 1.6 !important;
    position: relative;
    margin-bottom: 15px !important;
}
.articleOl li::before {
    content: counter(li) '.';
    counter-increment: li;
    color: #6AE1C6;
    font-family: "Graphie";
    font-weight: 700;
    display: inline-block;
    width: 1em;
    margin-right: 1em;
    letter-spacing: 1px;
    left: -65px;
    position: absolute;
}

@media (max-width: 768px) {
    p.dropCap:first-letter {
        font-size: 100px;
        line-height: 95px;
        padding-top: 0;
    }
    .articlePage p {
        font-size: 16px !important;
    }
    h1 {
        font-size: 46px !important;
    }
    h2 {
        font-size: 42px !important;
    }
    h3 {
        font-size: 32px !important;
    }
    h4 {
        font-size: 18px !important;
    }
    h5 {
        font-size: 16px !important;
    }
    blockquote {
        font-size: 13px;
    }
    .articleAnchor, .articleAnchor:link {
        font-size: 16px;
    }
    .articleUl li {
        font-size: 16px !important;
    }
    .articleOl li {
        font-size: 16px !important;
    }
}

.content-block h1,
.content-block h2,
.content-block h3,
.content-block h4,
.content-block h5,
.content-block h6,
.content-block h1,
.content-block h2,
.content-block h3,
.content-block h4,
.content-block h5,
.content-block h6
{
    margin-top: 0 !important;
}

.content-block.div.h5, .content-block dark h5 {
    letter-spacing: 2.5px;
}

.content-block p {
    color: #4D4E56;
    line-height: 1.6 !important;
}

[class*=html-blurb] p, [class*=html-blurb] p strong, .content-block p, .content-block p strong {
    color: #4D4E56;
}

.html-blurb-center .articleUl, .html-blurb-center .articleOl {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.html-blurb-left .articleUl, .html-blurb-left .articleOl {
    width: fit-content;
    margin-right: auto;
}

.html-blurb-right .articleUl, .html-blurb-right .articleOl {
    width: fit-content;
    margin-left: auto;
}

[class*=html-blurb] h5 {
    letter-spacing: 4px !important;
}

@media (max-width: 576px) {
    h3 {
        font-size: 25px !important;
    }
}

h3 .html-blurb-highlight-wrapper {
    display: inline-block;
    position: relative;
}

h3 .html-blurb-highlight {
    background-color: #E1F8ED;
    opacity: 0.75;
    width: 100%;
    position: absolute;
    height: 23px;
    top: 22px;
    left: 5px;
    z-index: -1;
    border-radius: 5px;
}

h3 .html-blurb-highlight-text {
    display: inline;
    position: relative;
    z-index: 0;
}

.dark h1, .dark h3, .dark h4{
    color: #6AE1C6;
}

.dark h2 {
    color: #59DDC0;
}

.dark h3 {
    color: #FFFFFF;
}

.dark h5, .dark h6 {
    color: #FFFFFF;
}

.dark p, .dark p strong{
    color: #FFFFFF;
}

.dark .articleUl li, .dark .articleOl li {
    color: #FFFFFF;
}

.dark .articleAnchor, .dark .articleAnchor:link {
    color: #6AE1C6 !important;
}

.content-block .articleUl li::before {
    top: -14px;
}

.highlight,  .highlight-dark{
    background-repeat: no-repeat;
    background-position: 10px 0;
    background-size: 97%;
    background-color: transparent !important;
    padding-right: 5px !important;
    padding-bottom: 5px !important;
}

p .highlight, p .highlight-dark {
    background-position: 5px 0 !important;
    padding-bottom: 0 !important;
}

.highlight {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 45.33%, #E1F8ED 45.33%, #E1F8ED 93.66%, rgba(0, 0, 0, 0) 87.66%);
}

.highlight-dark {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 45.33%, rgba(106, 225, 198, 0.25) 45.33%, rgba(106, 225, 198, 0.25) 93.66%, rgba(0, 0, 0, 0) 87.66%);
    color: #FFFFFF !important;
}

mark {
    padding: 0 !important;
}

.mega-menu h1, .mega-menu h2, .mega-menu h3, .mega-menu h4, .mega-menu h5, .mega-menu h6 {
    margin-bottom: 5px !important;
    margin-top: 0px !important;
}
