#logoImageNav {
    height: 65px;
    width: 147.197px ;
    padding: 10px;
    margin-left: 0px;
    /*transform: translateY(-100%);*/
    /*opacity: 0;*/
    cursor: pointer;
    transform: translate3d(0, 0, 0);
}

#logoImage {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vmin;
    height: auto;
    transform: translate(-50%, -50%);
}

.logo {
    width: 24px;
    margin: 6px;
}

#oos-logo {
    margin: 10px;
    margin-top: 5px;
    width: 60px;
}