#who-are-we-image {
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
}

#who-are-we-description-text {
    color: #6AE1C6;
    font-family: 'Graphie';
    text-align: right;
    font-weight: 300;
    font-size: 32px;
}

#who-are-we-description {
    width: 90%;
    float: right;
}

#who-are-we-canvas-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.computer-animation {
    padding-bottom: 60px;
    padding-top: 60px;
    background-color: #6AE1C6;
    background-color: white;
    -webkit-transform: translate3d(0, 0, 0);
}

.ca-content {
    max-width: 1260px;
    width: 100%;
    padding: 0 60px;
    margin-left: auto;
    margin-right: auto;
}

.ca-columns {
    display: table;
    width: 100%;
}

.ca-column {
    width: 50%;
    display: table-cell;
    position: relative;
}

.canvas-wrapper {
    height: 100%;
    transform: translateY(-25%);
    -webkit-transform: translateY(-25%);
}

.canvas-wrapper-final {
    transform: translateY(25%);
    -webkit-transform: translateY(25%);
}

.slogan-list {
    display: block;
    padding-left: 0px;
    padding-right: 0px;
}

#computer-animation-canvas {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);

}

@media screen and (max-width: 1024px) {
    .ca-column {
        width: 100% !important;
        float: left;
        display: block;
    }
    #who-are-we-canvas-content{
        position: relative;
        transform: none;
    }
    #who-are-we-description {
        width: 100%;
    }
    #who-are-we-description-text {
        text-align: center;
        font-size: 28px;
    }
    #who-are-we-image {
        padding-left: 0px;
        padding-right: 0px;
    }
    #canvasWrapper {
        height: 75vw;
    }
    .computer-animation {
        padding-top: 75px;
    }

    .canvas-wrapper {
        transform: translateY(-15%);
        -webkit-transform: translateY(-15%);
    }

    .canvas-wrapper-final {
        transform: translateY(15%);
        -webkit-transform: translateY(15%);
    }
}

@media screen and (max-width: 512px) {
    #who-are-we-description-text {
        font-size: 24px;
    }
}
