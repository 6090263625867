.about-us {
    position: relative;

}

.about-us-section {
    position: relative;
    padding-top: 100px;
    padding-bottom: 30px;
    background-color: white;
    line-height: 27px;
}

.about-us-content {
    max-width: 1170px;
    width: 100%;
    padding-left: 60px;
    padding-right: 60px;
    margin-left: auto;
    margin-right: auto;
}

.about-us-header {
    color: rgba(119, 119, 119, 0.4);
    font-family: "Graphie";
    text-align: center;
    font-weight: 500;
    font-size: 34px;
    padding-bottom: 40px;
}

.about-us-bios {
    padding-left: 0;
}

@media screen and (max-width: 768px) {
    .about-us-description {
        font-size: 18px !important;
    }
}

.about-us-description {
    width: 100%;
    color: #4D4E56;
    text-align: center;
    font-size: 21px;
    vertical-align: middle;
    font-family: "Graphie";
    font-style: normal;
    font-weight: 400;
    padding-bottom: 50px;

}
