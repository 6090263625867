#wwd-container {
    background-image: linear-gradient(#231F20 13%, #007589);
    height: 941px;
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-right: 35px;
    padding-left: 35px;
    padding-top: 35px;
}

#wwd-title {
    font-size: 70px !important;
    margin: 0 !important;
    font-weight: 200;
    color: #6AE1C6;
    text-transform: uppercase;
}

#wwd-title-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 55px;
    padding-bottom: 70px;
}

#wwd-background-overlay {
    mix-blend-mode: overlay;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    will-change: transform;
    object-fit: cover;
    object-position: bottom;
    mask-image: linear-gradient(to bottom, rgba(0,0,0,0) 13%, rgba(0,0,0,1));
}

#wwd-stripe-overlay {
    mix-blend-mode: overlay;
    position: absolute;
    will-change: transform;
}

#wwd-grid-card-container {
    z-index: 2;
    width: 100%;
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    justify-content: center;
}

@media(max-width: 1290px) {
    #wwd-container {
        padding-right: 35px;
        padding-left: 35px;
    }
    #wwd-title {
        font-size: 70px !important;
    }
    #wwd-title-container {
        padding-bottom: 18px;
    }
}

@media(max-width: 759px) {
    #wwd-container {
        background-image: linear-gradient(#231F20, #007589);
        height: 1265px;
    }
    #wwd-title {
        font-size: 45px !important;
    }
    #wwd-title-container {
        padding-top: 37px !important;
    }
    #wwd-background-overlay {
        width: 140%;
    }
}

@media(max-width: 576px) {
    #wwd-title {
        font-size: 40px !important;
    }
    #wwd-title-container {
        padding-top: 0 !important;
    }
}

@media(max-width: 400px) {
    #wwd-container {
        padding-right: 15px;
        padding-left: 15px;
    }
}
